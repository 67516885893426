var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input drop-item",style:({width: _vm.width, margin: _vm.margin})},[_c('div',{staticClass:"dropdown",style:({backgroundColor: _vm.bgColor ? _vm.bgColor : 'var(--brand-area-color)',
    borderRadius: !_vm.open ? 'var(--component-border)' : 'var(--component-border) var(--component-border) 0 0',
    borderBottomColor: _vm.borderColor,
    height: _vm.height ? _vm.height : '2.5rem',
    boxShadow: _vm.open ? 'var(--component-elevation-on-focus)' : '',
    border: _vm.borderFull
  })},[_c('button',{staticClass:"flex w-full",on:{"click":function($event){return _vm.onToggle()}}},[_c('div',{staticClass:"display w-full self-center"},[_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm._f("sentenceCase")(_vm.label)))]),_c('span',[(_vm.searcheable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],ref:"input",attrs:{"type":"text","placeholder":_vm.placeholder},domProps:{"value":(_vm.search)},on:{"click":function($event){_vm.open = true;},"input":[function($event){if($event.target.composing)return;_vm.search=$event.target.value},function($event){_vm.open = true; _vm.setCustomInput()}]}}):_vm._e(),(!_vm.searcheable)?_c('p',{style:({margin: _vm.icon ? '0.2rem 0.5rem' : '0.2rem 0'})},[_vm._v(" "+_vm._s(_vm.item.value || _vm.placeholder)+" ")]):_vm._e()])]),_c('font-awesome-icon',{staticClass:"self-center",attrs:{"icon":_vm.open ? 'angle-up' : 'angle-down',"size":"2x","color":"#9a9da9"}})],1)]),(_vm.open)?_c('div',{staticClass:"dropdown-content",style:({boxShadow: _vm.open ? 'var(--component-elevation-on-focus)' : ''})},[_c('ul',_vm._l((_vm.filteredList),function(item,index){return _c('li',{key:index},[(item === 'divider' && index !== _vm.filteredList.length-1)?_c('b-dropdown-divider'):_c('button',{on:{"click":function($event){return _vm.onSelect(item)}}},[_c('p',[(item.visited)?_c('img',{attrs:{"src":require("@/assets/icons/ico-check-circle.svg")}}):_vm._e(),_c('span',{class:item.visited ? '' : 'ml-8'},[_vm._v(_vm._s(item.value))])])])],1)}),0)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }