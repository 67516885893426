import {DATA_TYPES} from "@shared/utils/content-scripts/mark.decorator";
import {escapeRegExp} from "@shared/utils/functions";

/**
 * Negative Word Entity properties
 *  @param {Object} source - object with Negative Word Entity
 *   @param {Boolean} source.boundary - boundary
 *   @param {String} source.category - category
 *   @param {String} source.color - HEX color string "#9DCFFB"
 *   @param {Boolean} source.enabled - enabled
 *   @param {String} source.word - word
 *   @param {String} source.word_type - word_type
 *   @param {String} source._id - id
 *
 *   @return {Object};
 */

function ProductWordEntityToMarkEntityAdapter(source) {
    return {
        getEntity() {
            return {
                element: source,
                dataType: DATA_TYPES.productKeywords,
                pattern: new RegExp(`${escapeRegExp(source.word.trim())}`, "gmi"),
            };
        },
        getClasses() {
            const fixedClass = "neotas-list product-words";
            const categoryClass = `neo-category-${source.word_type}`;
            const colorClass = `neo-color-${source.color.replace("#", "").toLowerCase()}`;
            return `${fixedClass} ${categoryClass} ${colorClass}`;
        },
        getAttributes() {
            const attributes = [];
            const hoverLabel = this.getHoverLabelAttribute();
            attributes.push(hoverLabel);
            return attributes;
        },
        getHoverLabelAttribute() {
            const hoverLabel = [];
            hoverLabel.push(source.category);
            hoverLabel.push(source.language ? source.language : "English");

            hoverLabel.push(source.word);

            const label = hoverLabel.join(" - ");
            return {key: "title", value: label};
        },
    };
}

export default ProductWordEntityToMarkEntityAdapter;
