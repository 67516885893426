import {uniq} from "lodash";
import ApiDataProvider from "@shared/utils/content-scripts/api-data.provider";
import StylesProvider from "@shared/utils/content-scripts/styles.provider";
import MarkDecorator from "@shared/utils/content-scripts/mark.decorator";
import {uuidv4} from "@shared/utils/saas-functions";
import MLEEntityToMarkEntityAdapter from "@shared/utils/content-scripts/entitie-adapters/mle-entity-to-mark-entity.adapter";
import CaseIdentifierEntityToMarkEntityAdapter from "@shared/utils/content-scripts/entitie-adapters/case-identifier-entity-to-mark-entity.adapter";
import SelectedKeywordToMarkEntityAdapter from "@shared/utils/content-scripts/entitie-adapters/selected-keyword-to-mark-entity.adapter";
import ProductWordEntityToMarkEntityAdapter from "@shared/utils/content-scripts/entitie-adapters/product-word-entity-to-mark-entity.adapter";
import NegativeWordEntityToMarkEntityAdapter from "@shared/utils/content-scripts/entitie-adapters/negative-word-entity-to-mark-entity.adapter";
import SearchWordToMarkEntityAdapter from "@shared/utils/content-scripts/entitie-adapters/search-word-to-mark-entity.adapter";

// facade for mark lib and queries to the server
function NlpAnalysisFacade(htmlElement, caseId, getSelectedKeywords, getSearchWords, useServerHighlight) {
    const id = uuidv4();

    const apiProvider = ApiDataProvider;
    const markDecorator = MarkDecorator(htmlElement, useServerHighlight);
    const stylesProvider = StylesProvider;

    return {
        getId() {
            return id;
        },
        async highlightNegativeWords(badWordsLanguages) {
            let badWords = await apiProvider.loadAndFilterBadWords((element) => element.word_type === "adverse");

            if (badWordsLanguages.length === 1 && badWordsLanguages[0] === "All Languages") {
                // All languages, don't do anything
            } else {
                badWords = badWords.filter((e) => badWordsLanguages.includes(e.language));
            }

            let colors = uniq(badWords.map((e) => e.color.toLowerCase()));
            colors.forEach((e) => {
                stylesProvider.createClass(`.neo-color-${e.replace("#", "")}`, `background-color: ${e} !important;`);
            });

            const markEntityAdapters = badWords.map((item) => NegativeWordEntityToMarkEntityAdapter(item));
            await markDecorator.highlightNegativeWords(markEntityAdapters);
        },
        async highlightProductWords() {
            const productWords = await apiProvider.loadAndFilterBadWords((element) => element.word_type === "product");

            let colors = uniq(productWords.map((e) => e.color.toLowerCase()));
            colors.forEach((e) => {
                stylesProvider.createClass(`.neo-color-${e.replace("#", "")}`, `background-color: ${e} !important;`);
            });

            const markEntityAdapters = productWords.map((item) => ProductWordEntityToMarkEntityAdapter(item));
            await markDecorator.highlightProductWords(markEntityAdapters);
        },
        async highlightCaseIdentifiers() {
            let entitiesData = await apiProvider.loadEntitiesData(caseId);

            let localEntities = [];
            entitiesData.main_node.forEach((f) => {
                localEntities.push({word: f.entity.toLowerCase(), title: f.title});
            });
            entitiesData.entities.forEach((f) => {
                localEntities.push({word: f.entity.toLowerCase(), title: f.title});
            });

            const markEntityAdapters = localEntities.map((item) => CaseIdentifierEntityToMarkEntityAdapter(item));
            await markDecorator.highlightCaseIdentifiers(markEntityAdapters);
        },
        async highlightMLEntities() {
            const pageText = htmlElement.outerText;
            const url = window.location.origin;

            const nlpEntities = await apiProvider.loadNLPEntities(url, pageText);

            const markEntityAdapters = nlpEntities.map((item) => MLEEntityToMarkEntityAdapter(item));
            await markDecorator.highlightMLEntities(markEntityAdapters);
        },
        async highlightSelectedKeywords() {
            const selectedKeywords = getSelectedKeywords();
            const markEntityAdapters = selectedKeywords.map((item) => SelectedKeywordToMarkEntityAdapter(item));
            await markDecorator.highlightSelectedKeywords(markEntityAdapters);
        },
        async highlightSearchWords() {
            if (useServerHighlight) {
                return;
            }
            const searchWords = getSearchWords();
            const markEntityAdapters = searchWords.map((item) => SearchWordToMarkEntityAdapter(item));
            await markDecorator.highlightSearchWords(markEntityAdapters);
        },
        clear() {
            markDecorator.unmark();
        },
        getEntitiesCount() {
            return {
                id,
                count: markDecorator.getEntitiesCount(),
            };
        },
    };
}

export default NlpAnalysisFacade;
