import {mapGetters} from "vuex";
import {dragscroll} from "vue-dragscroll";
import Checkbox from "@/components/checkbox";
import {TippyComponent} from "vue-tippy";
import {orderBy} from "lodash";
import axios from "@/axios";

export default {
    name: "entity-selector",
    components: {
        "neo-checkbox": Checkbox,
        tippy: TippyComponent,
    },
    directives: {
        dragscroll,
    },
    props: {
        readOnly: {
            type: Boolean,
            default: false,
        },
        entitiesList: {
            type: Array,
            default: false,
        },
        currentTab: {
            type: String,
            default: false,
        },
        tab: {
            type: Boolean,
            default: false,
        },
        selectedEntityId: {
            type: Object,
            default: false,
        },
    },
    data() {
        return {
            selectedEntities: [],
            dragging: false,
            select_all: false,
            showOnlyMain: true,
            show_associated: false,
            scrollerVisible: false,
        };
    },
    computed: {
        ...mapGetters(["getIconsData", "getAllAssociatedEntities", "getUserDetails"]),
        selectedEntityIds() {
            if (this.tab) {
                this.select_all = false;
                return (this.selectedEntities = []);
            }
            if (Object.keys(this.selectedEntityId).length > 0 && this.currentTab === "prebuilt") {
                this.selectedEntities.push(this.selectedEntityId);
            }
            return this.selectedEntities.map((e) => e._id);
        },
        validEntities() {
            let entityData = this.getAllAssociatedEntities.map((e) => ({
                ...e,
                main_entity: e.main_entity ? e.main_entity : e.attributes.filter((f) => f.attribute_id == "ffe23e7b-6477-49d6-b12b-793d4212c31a" && f.value === true).length > 0,
            }));
            if (!this.showOnlyMain) {
                entityData = entityData.filter((e) => e.main_entity === true);
            }
            return orderBy(entityData, ["main_entity"], ["desc"]);
        },
    },
    mounted() {},
    methods: {
        switchAssociated() {
            // TODO: Need to add code here.
        },
        dragStart() {
            this.dragging = true;
        },
        dragEnd() {
            this.dragging = false;
        },
        clickScroll(direction) {
            let currentScroll = this.$refs.entityScrollContainer.scrollLeft;
            let targetScroll = direction == "left" ? currentScroll - 100 : currentScroll + 100;
            if (targetScroll < 0) targetScroll = 0;
            this.$refs.entityScrollContainer.scrollTo({
                left: targetScroll,
                behavior: "smooth",
            });
        },
        switchSelect() {
            if (this.select_all) {
                this.selectedEntities = [...this.validEntities];
            } else {
                this.selectedEntities = [];
            }
            this.$emit("entitySelected", this.selectedEntities);
        },
        async selectEntity(entity) {
            if (this.currentTab === "prebuilt") {
                let index = this.selectedEntities.findIndex((el) => el._id === entity._id);
                if (index < 0) {
                    this.selectedEntities = [];
                    this.selectedEntityId = [];
                    this.$emit("entitySelected", "clear");
                    this.selectedEntities.push(entity);
                    const selectedEntity = await axios.get(`search-engine/case-entity-details/${entity._id}`);
                    this.$emit("entitySelected", selectedEntity.data.data, entity);
                } else {
                    this.selectedEntities.splice(index, 1);
                    this.$emit("entitySelected", "clear");
                }
            } else if (this.currentTab === "case_query") {
                this.selectedEntityId = [];
                let index1 = this.selectedEntities.findIndex((el) => el._id === entity._id);
                if (index1 < 0) {
                    this.selectedEntities.push(entity);
                } else {
                    this.selectedEntities.splice(index1, 1);
                }
                this.select_all = this.selectedEntities.length == this.validEntities.length;
                let selected = this.selectedEntities.map((el) => el._id);
                this.$emit("entitySelected", null, selected);
            }
        },
        getIcon(key) {
            return this.getIconsData.find((e) => e.key == key)?.icon_data ?? "";
        },
    },
};
