import {mapGetters} from "vuex";
import frequencyWidget from "@/components/frequency-widget";
import NeoToggleSwitch from "@/components/toggle-switch";
import {required, integer, minValue, maxValue} from "vuelidate/lib/validators";
import axios from "@/axios";

export default {
    name: "options-widget",
    components: {
        "frequency-widget": frequencyWidget,
        NeoToggleSwitch,
    },
    props: {
        monitoring_data: null,
        tool: "",
        source: "",
    },
    data() {
        return {
            frequencyList: [1, 7, 15, 30, 60, 90, 180, 365],
            frequency: null,
            count: 10,
            duration: 7,
            monitoring_status: false,
        };
    },
    watch: {},
    computed: {
        ...mapGetters(["getProduct"]),
        newResultsCount() {
            let count = 0;
            return count;
        },
        disabled() {
            return false;
        },
        product() {
            return this.getProduct;
        },
    },
    mounted() {},
    methods: {
        // Function to get monitoring data
        async getDataMonitoring() {
            let inherit_settings = null;
            let url = `/data-monitoring/product?product_id=${this.product?.key}&tool=${this.tool}`;
            // let data = {
            //     product_id: this.product?.key,
            //     tool: this.tool,
            // };
            try {
                let response = await axios.post(url);
                // let response = await axios.post(url, data);
                if (response?.status === 200 && response?.data?.data) {
                    let productMonitoring = response?.data?.data[0];

                    inherit_settings = {
                        frequency: productMonitoring?.frequency,
                        status: productMonitoring?.status,
                    };
                }
            } catch (error) {
                console.error(error);
            }
            return inherit_settings;
        },

        handleMonitoringStatus() {
            this.monitoring_status = !this.monitoring_status;
            this.$forceUpdate();
        },

        // Reset settings to default from product level
        async resetSettings() {
            let inherit_settings = await this.getDataMonitoring();

            this.frequency = inherit_settings?.frequency;
            this.monitoring_status = inherit_settings?.status === "enabled";

            this.$forceUpdate();
        },
        updateFrequency() {
            this.$v.$touch();

            if (this.$v.$invalid && this.source === "monitoring" && this.$route.name === "monitoring" && this.tool === "search-engine-tool") {
                return;
            }

            if (!this.frequency) this.$toast.error("Please select frequency for the tool / query");
            else {
                this.$emit("updateFrequency");
                let monitoring_data = {
                    frequency: this.frequency,
                    monitoring_status: this.monitoring_status ? "enabled" : "disabled",
                };
                if (this.source === "monitoring" && this.$route.name === "monitoring" && this.tool === "search-engine-tool") {
                    monitoring_data = {
                        ...monitoring_data,
                        ...{
                            count: this.count,
                            duration: this.duration,
                        },
                    };
                }
                this.$emit("updateMonitoring", monitoring_data);
                this.$parent.tippy().hide();
            }
        },

        initiateOptionsWidget() {
            this.monitoring_status = this.monitoring_data?.monitoring_status;
            this.frequency = this.monitoring_data?.frequency || null;
            this.count = this.monitoring_data?.count || 10;
            this.duration = this.monitoring_data?.duration || 7;
            this.$forceUpdate();
        },

        resetOptionsWidget() {
            this.monitoring_status = false;
            this.frequency = null;
            this.count = 10;
            this.duration = 7;
            this.$forceUpdate();
        },
    },
    validations: {
        count: {
            required,
            integer,
            minValueValue: minValue(5),
            maxValueValue: maxValue(100),
        },
        duration: {
            required,
            integer,
            minValueValue: minValue(1),
            maxValueValue: maxValue(365),
        },
    },
};
