import Button from "@/components/button";
import {EventBus} from "@/main.js";

export default {
    name: "frequency-widget",
    components: {
        "neo-button": Button,
    },
    props: {
        frequency: 0,
        source: "",
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            frequencyList: [1, 7, 15, 30, 60, 90, 180, 365],
        };
    },
    watch: {},
    computed: {},
    mounted() {
        document.querySelector("#frequency").value = this.frequency;
        console.log("11")
    },
    methods: {
        updateFrequency() {
            let frequency = document.querySelector("#frequency").value;
            this.$emit("update");
            EventBus.$emit("updateFrequency", frequency);
        },
    },
};
