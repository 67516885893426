export default {
    name: "neo-button",
    props: {
        control: Boolean,
        label: String,
        color: String,
        borderStyle: String,
        stroke: String,
        icon: String,
        iconColor: String,
        hoverIconColor: String,
        hoverBg: String,
        hoverText: String,
        hoverStroke: String,
        margin: String,
        iconPos: String,
        iconSize: String,
        bg: {
            type: String,
            default: "transparent",
        },
        borderRadius: {
            type: String,
            default: "5rem",
        },
        padding: {
            type: String,
            default: "0.75rem 1rem",
        },
        fontSize: {
            type: String,
            default: "14px",
        },
        disabled: Boolean,
        componentClass: String,
    },
    data() {
        return {
            active: false,
            bgcolor: this.bg,
            txtcolor: this.color,
            iColor: this.iconcolor,
            strokeColor: this.stroke,
        };
    },
    computed: {
        backgroundColor() {
            return this.bgcolor;
        },
        textColor() {
            return this.txtcolor;
        },
        buttonIconColor() {
            return this.iColor;
        },
        buttonStroke() {
            return this.strokeColor;
        },
    },
    mounted() {},
    watch: {
        stroke(newValue) {
            this.strokeColor = newValue;
        },
        bg(newValue) {
            this.bgcolor = newValue;
        },
    },
    methods: {
        mouseEnter() {
            this.bgcolor = this.hoverBg;
            this.txtcolor = this.hoverText;
            this.iColor = this.hoverIconColor;
            this.strokeColor = this.hoverStroke || this.stroke;
        },
        mouseLeave() {
            this.bgcolor = this.bg;
            this.txtcolor = this.color;
            this.iColor = this.iconColor;
            this.strokeColor = this.stroke;
        },
        onClick() {
            if (this.control) {
                this.active = !this.active;
                this.$emit("active", this.active);
            }
            this.$emit("click");
        },
    },
};
