export default {
    name: "toggle-switch",
    components: {},
    props: ["status", "disabled"],
    data() {
        return {};
    },
    computed: {
        optionalClass() {
            if (this.disabled) {
                if (this.status) {
                    return "bg-blue-400 bg-gray-100   cursor-not-allowed pointer-events-none";
                }
                return "bg-gray-100 cursor-not-allowed pointer-events-none  opacity-50";
            } else if (this.status) return "bg-brand ";
            else return;
        },
    },
    mounted() {},
    methods: {},
};
