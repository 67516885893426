<template>
    <div class="rounded-lg w-full cursor-pointer">
        <h2 class="head_accordion pb-1 border rounded-lg bg-white cursor-pointer filter">
            <button ref="toggle-button" @click="toggleAccordion" class="group relative flex w-full justify-between items-center pt-3 border-0 bg-white" :aria-expanded="isOpen" :aria-controls="`collapse${_uid}`">
                <div class="flex justify-between w-full pr-2 pt-3 cursor-pointer" @click="fetchData">
                    <slot name="title" />
                    <slot class="" name="titleCheckbox" />
                </div>
                <span>
                    <svg
                        class="w-3 transition-all duration-200 transform"
                        :class="{
                            'rotate-180': isOpen,
                            'rotate-0': !isOpen,
                        }"
                        fill="none"
                        stroke="var(--text-fade-color)"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 10"
                        aria-hidden="true"
                    >
                        <path d="M15 1.2l-7 7-7-7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
            </button>
        </h2>
        <div v-show="isOpen" :id="`collapse${_uid}`">
            <slot name="content" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isOpen: false,
        };
    },
    props: {
        fetchSelectedFilter: Function,
        filter: Object,
        customTitle: Array,
    },
    mounted() {
        if (this.filter.data.length != 0 && this.filter.name != "matchingIdentifiers") {
            this.isOpen = true;
        }
    },
    methods: {
        toggleAccordion(e) {
            if (e.target.id !== "checkboxInput") {
                this.isOpen = !this.isOpen;
                // e.stopPropogation();
            }
        },
        fetchData() {
            if (!this.isOpen && this.filter) {
                this.fetchSelectedFilter(this.filter, this.customTitle);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.head_accordion {
    background-color: #bfe0f5 !important;
}

.filter {
    background: transparent !important;
    border: none;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom: 1px solid #ccc !important;
    border-radius: 0px;
}
</style>
