function CreateStylesProvider() {
    const createdClasses = new Set();

    return {
        createClass(name, rules) {
            if (createdClasses.has(name)) {
                return;
            }

            const style = document.createElement("style");
            style.setAttribute("type", "text/css");
            style.innerHTML = `${name} { ${rules} }`;
            document.getElementsByTagName("head")[0].appendChild(style);

            createdClasses.add(name);
        },
    };
}

const StylesProvider = CreateStylesProvider();
export default StylesProvider;
