import {DATA_TYPES} from "@shared/utils/content-scripts/mark.decorator";
import {escapeRegExp} from "@shared/utils/functions";

/**
 * MLE Entity properties
 *  @param {Object} source - object with MLE Entity
 *   @param {Object} source.attributes - Objects with attributes (neo-data-key, neo-data-text, neo-data-text-lower, neo-entity-label, neo-entity-type, title)
 *   @param {String} source.entity_label - Entity label
 *   @param {String} source.entity_type - Entity type
 *   @param {Array} source.extra_class - Array with String class names
 *   @param {String} source.main_class - Entity main class
 *   @param {String} source.text - Entity text
 *
 *   @return {Object};
 */

function MLEEntityToMarkEntityAdapter(source) {
    return {
        getEntity() {
            const elementForRender = {
                word: source.text,
                raw_element: source,
            };
            return {
                element: elementForRender,
                dataType: DATA_TYPES.nlpEntities,
                pattern: new RegExp(`${escapeRegExp(source.text.trim())}`, "gmi"),
            };
        },
        getClasses() {
            const fixedClass = `ml-entities ${source.main_class} ${source.extra_class.join(" ")}`;
            return `${fixedClass}`;
        },
        getAttributes() {
            const attributes = [];
            if (source.attributes != null && Object.keys(source.attributes).length > 0) {
                Object.keys(source.attributes).forEach((attribute) => {
                    attributes.push({key: attribute, value: source.attributes[attribute]});
                });
            }

            attributes.push({
                key: "neo-entity-type",
                value: source.entity_label,
            });
            return attributes;
        },
    };
}

export default MLEEntityToMarkEntityAdapter;
