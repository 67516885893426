function CreateNLPAnalysisCollection() {
    const nlpAnalysisFacades = new Map();

    function getListOfNlpAnalysisFacades() {
        return Array.from(nlpAnalysisFacades.values());
    }

    return {
        isEmpty() {
            return nlpAnalysisFacades.size === 0;
        },
        subscribe(nlpAnalysisFacade) {
            nlpAnalysisFacades.set(nlpAnalysisFacade.getId(), nlpAnalysisFacade);

            return {
                id: nlpAnalysisFacade.getId(),
                unsubscribe: () => nlpAnalysisFacades.delete(nlpAnalysisFacade.getId()),
            };
        },
        async highlightNegativeWords(badWordsLanguages) {
            const listOfNlpFacades = getListOfNlpAnalysisFacades();
            await Promise.all(listOfNlpFacades.map((nlpAnalysisFacade) => nlpAnalysisFacade.highlightNegativeWords(badWordsLanguages)));
        },
        async highlightProductWords() {
            const listOfNlpFacades = getListOfNlpAnalysisFacades();
            await Promise.all(listOfNlpFacades.map((nlpAnalysisFacade) => nlpAnalysisFacade.highlightProductWords()));
        },
        async highlightCaseIdentifiers() {
            const listOfNlpFacades = getListOfNlpAnalysisFacades();
            await Promise.all(listOfNlpFacades.map((nlpAnalysisFacade) => nlpAnalysisFacade.highlightCaseIdentifiers()));
        },
        async highlightMLEntities() {
            const listOfNlpFacades = getListOfNlpAnalysisFacades();
            await Promise.all(listOfNlpFacades.map((nlpAnalysisFacade) => nlpAnalysisFacade.highlightMLEntities()));
        },
        async highlightSelectedKeywords() {
            const listOfNlpFacades = getListOfNlpAnalysisFacades();
            await Promise.all(listOfNlpFacades.map((nlpAnalysisFacade) => nlpAnalysisFacade.highlightSelectedKeywords()));
        },
        async highlightSearchWords() {
            const listOfNlpFacades = getListOfNlpAnalysisFacades();
            await Promise.all(listOfNlpFacades.map((nlpAnalysisFacade) => nlpAnalysisFacade.highlightSearchWords()));
        },
        clear() {
            for (let nlpAnalysisFacade of nlpAnalysisFacades.values()) {
                nlpAnalysisFacade.clear();
            }
        },
        getEntitiesCount() {
            const listOfNlpFacades = getListOfNlpAnalysisFacades();

            return listOfNlpFacades.map((nlpAnalysisFacade) => nlpAnalysisFacade.getEntitiesCount());
        },
    };
}

const NLPAnalysisCollection = CreateNLPAnalysisCollection();
export default NLPAnalysisCollection;
