import neoAxios from "@/axios";
import rawAxios from "axios";

function CreateApiDataProvider() {
    let badWordsCache = [];
    let badWordsLoading = false;
    let badWordsQueue = [];

    const entityIdentifierCache = new Map();
    let entityIdentifierLoading = false;
    const entityIdentifierQueue = new Map();

    const nlpEntityCache = new Map();

    return {
        async loadAndFilterBadWords(filterDelegate = () => true) {
            if (badWordsLoading) {
                return new Promise((resolve) => {
                    badWordsQueue.push((badWords) => resolve(badWords.filter(filterDelegate)));
                });
            }

            if (badWordsCache.length === 0) {
                badWordsLoading = true;

                const badWordsResponse = await neoAxios.get(`/bad-words`);
                badWordsCache = badWordsResponse.data;

                badWordsLoading = false;
            }

            if (badWordsQueue.length > 0) {
                for (let badWordsQueueItem of badWordsQueue) {
                    badWordsQueueItem(badWordsCache);
                }

                badWordsQueue = [];
            }

            return badWordsCache.filter(filterDelegate);
        },
        async loadEntitiesData(caseId) {
            if (entityIdentifierLoading) {
                const entityIdentifierCaseQueue = entityIdentifierQueue.has(caseId) ? entityIdentifierQueue.get(caseId) : [];
                return new Promise((resolve) => {
                    entityIdentifierCaseQueue.push(resolve);
                    entityIdentifierQueue.set(caseId, entityIdentifierCaseQueue);
                });
            }

            if (entityIdentifierCache.has(caseId)) {
                return entityIdentifierCache.get(caseId);
            }

            entityIdentifierLoading = true;
            const entitiesDataResponse = await neoAxios.get(`/entity-identifier?caseid=${caseId}`);
            const entitiesData = entitiesDataResponse.data;
            entityIdentifierLoading = false;

            entityIdentifierCache.set(caseId, entitiesData);

            if (entityIdentifierQueue.has(caseId)) {
                const entityIdentifierCaseQueue = entityIdentifierQueue.get(caseId);

                for (let entityIdentifierQueueItem of entityIdentifierCaseQueue) {
                    entityIdentifierQueueItem(entitiesData);
                }

                entityIdentifierQueue.delete(caseId);
            }

            return entitiesData;
        },
        async loadNLPEntities(url, pageText, pageHTML = "abc") {
            const payload = {url, page_text: pageText, page_html: pageHTML};

            const cacheKey = JSON.stringify(payload);
            if (nlpEntityCache.has(cacheKey)) {
                return nlpEntityCache.get(cacheKey);
            }

            const nlpResponse = await rawAxios.post("https://corenlp.neotas.uk/api/sentence/ner/", payload);
            const nlpEntities = nlpResponse.data.final_data;

            nlpEntityCache.set(cacheKey, nlpEntities);

            return nlpEntities;
        },
    };
}

const ApiDataProvider = CreateApiDataProvider();
export default ApiDataProvider;
