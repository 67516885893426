export default {
    name: "ne-loader",
    props: ["loading", "success", "height", "width", "margin"],
    components: {},
    data() {
        return {};
    },
    computed: {},
    mounted() {},
};
