import NlpAnalysisFacade from "@shared/utils/content-scripts/nlp-analysis.facade";
import NLPAnalysisCollection from "@shared/utils/content-scripts/nlp-analysis.collection";
import {mapGetters} from "vuex";

export default {
    name: "nlp-analysis-highlighting",
    data() {
        return {
            nlpAnalysisItem: null,
            nlpAnalysisItemSubscription: null,
            highlightingInProgress: false,
        };
    },
    props: {
        useServerHighlight: {
            type: Boolean,
            default: true,
        },
    },
    mounted() {
        this.initNLPAnalysis();
    },
    beforeDestroy() {
        if (this.nlpAnalysisItemSubscription != null) {
            this.$store.dispatch("removeHighlightedEntity", this.nlpAnalysisItemSubscription.id);
            this.nlpAnalysisItemSubscription.unsubscribe();
        }
    },
    methods: {
        async initNLPAnalysis() {
            this.nlpAnalysisItem = NlpAnalysisFacade(
                this.$el,
                this.$store.getters.getNeoCaseId,
                () => this.getSelectedKeywords,
                () => this.getSearchWords,
                this.useServerHighlight
            );
            this.nlpAnalysisItemSubscription = NLPAnalysisCollection.subscribe(this.nlpAnalysisItem);
            await this.highlightAll();
            const entityCount = this.nlpAnalysisItem.getEntitiesCount();
            this.$store.dispatch("addHighlightedEntity", entityCount);
        },
        async highlightAll() {
            if (this.highlightingInProgress) {
                return;
            }
            this.highlightingInProgress = true;

            const highlightPromises = [];

            if (this.getBadWordsChecked) {
                highlightPromises.push(this.nlpAnalysisItem.highlightNegativeWords(this.getBadWordsLanguages));
            }

            if (this.getCaseIdentifiersChecked) {
                highlightPromises.push(this.nlpAnalysisItem.highlightCaseIdentifiers());
            }

            if (this.getProductKeywordsChecked) {
                highlightPromises.push(this.nlpAnalysisItem.highlightProductWords());
            }

            highlightPromises.push(this.nlpAnalysisItem.highlightSelectedKeywords(this.getSelectedKeywords));

            if (!this.useServerHighlight) {
                highlightPromises.push(this.nlpAnalysisItem.highlightSearchWords(this.getSearchWords));
            }

            try {
                this.$nextTick(async () => {
                    await Promise.all(highlightPromises);
                });
            } finally {
                this.highlightingInProgress = false;
            }
        },
    },
    computed: {
        ...mapGetters(["getSelectedKeywords", "getSearchWords"]),
        ...mapGetters({
            getNearNumber: "getSelectedKeywordsNearNumber",
        }),
        getBadWordsChecked() {
            return this.$store.getters.getBadWordsChecked;
        },
        getBadWordsLanguages() {
            return this.$store.getters.getBadWordsLanguages;
        },
        getCaseIdentifiersChecked() {
            return this.$store.getters.getCaseIdentifiersChecked;
        },
        getProductKeywordsChecked() {
            return this.$store.getters.getProductKeywordsChecked;
        },
    },
};
