import moment from "moment";
import { get } from "lodash";
import axios from "@shared/light-ray/axios";

export function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}


export async function setUsernamesDropdownData(url, data) {
    const response = await axios.post(url, data);
    try {
        if (response && response.data && response.data) {
            return "success";
        }
    } catch (error) {
        console.log(error.message);
    }
  }

export function getMappingValue(data_values = [], source, mapValues, tag) {
    const result_dict = data_values.map((ele) => {
        let list = Object.entries(mapValues);
        const result = list.reduce((accumulator, item) => {
            let keys = item[1][source].keys;
            let separator = item[1][source].separator;
            accumulator[item[0]] = keys
                .map((key) => {
                    //Iterating inside array for fetching the values in "iterable_key"
                    if (item[1][source].iterable_key) {
                        return get(ele, key, []).map((el1) => get(el1, item[1][source].iterable_key, ""));
                    }
                    if (typeof item[1][source] === "object") {
                        return get(ele, key, []);
                    }
                    return get(ele, key, "N/A");
                })
                .filter((e) => e != null && e != "")
                .join(separator ? separator : ", ");
            return accumulator;
        }, {});
        result.key = source;
        result.sourceTag = tag;
        return {...result, api_all_data: ele};
    });
    return result_dict;
}

export function convertToCSV(arr) {
    let data = JSON.parse(JSON.stringify(arr));
    let keys = [];
    if (data && data.length) {
        keys = Object.keys(data[0]);
        for (let i = 0; i < data.length; i++) {
            let row = data[i];
            for (let j = 0; j < keys.length; j++) {
                let column = keys[j];
                if (typeof row[column] === "object" && row[column]) {
                    row[column] = parseCSV(row[column], column);
                }
            }
        }
        return data;
    } else {
        // Vue.$toast.error("Error: No data to download !");
        return [];
    }
}

export function parseCSV(data, key) {
    return JSON.parse(
            JSON.stringify(
                Object.keys(data)
                .map(function(k) {
                        if (typeof data[k] === "object" && data[k]) return `${key}.${k} : ${parseCSV(data[k], `${key}.${k}`)}`;
                    else return `${key}.${k} : ${data[k]}`;
                })
                .join(";")
        )
    );
}

export function csvFileName(tool, query, section) {
    let date = moment(new Date()).format("YYMMDD");
    if (query) return `${tool.replace(" ", "").toLocaleLowerCase()}_${query}_${section.replace(" ", "")}_${date.replace("-", "")}.csv`;
    else return `${tool.replace(/' '/g, "").toLocaleLowerCase()}_${section.replace(/' '/g, "")}_${date}.csv`;
}

export function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
        var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}