import Mark from "mark.js/dist/mark.es6";

export const DATA_TYPES = {
    negativeWords: "Negative Words",
    productKeywords: "Product Keywords",
    caseIdentifiers: "Case Identifiers",
    nlpEntities: "NLP Entities",
    selectedKeywords: "Selected Keywords",
    searchWords: "Search Words",
    otherWords: "Other Words",
};

export default function MarkDecorator(htmlElement, useServerHighlight) {
    const markInstance = new Mark(htmlElement);
    const htmlTag = useServerHighlight ? "hlt" : "hlt-client";

    /**
     * Highlighting text on the page by configurations
     *  @param {Object} entityAdapter - adapter with entity for Mark
     *   @param {Function} entityAdapter.getEntity - Returns entity.
     *   @param {Function} entityAdapter.getClasses - Returns classes string.
     *   @param {Function} entityAdapter.getAttributes - Returns array of object attributes (key, value)
     *
     *   @return void;
     */
    function markEntity(entityAdapter) {
        const entity = entityAdapter.getEntity();
        const entityClasses = entityAdapter.getClasses();
        const entityAttributes = entityAdapter.getAttributes();

        const defaultConfiguration = {
            element: htmlTag,
            acrossElements: true,
            className: `neo-highlight-entity ${entityClasses}`,
            exclude: [htmlTag],
            each: (el) => {
                el.style.zIndex = "1000";
                el.setAttribute("neo-entity-type", entity.dataType);
                el.setAttribute("neo-entity-value", entity.element.word);
                entityAttributes.forEach((attribute) => {
                    el.setAttribute(attribute.key, attribute.value);
                });
            },
        };

        markInstance.markRegExp(entity.pattern, defaultConfiguration);
    }

    function renderListOfItems(adapters, delay) {
        const promises = adapters.map((adapter, index) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    markEntity(adapters[index]);
                    resolve();
                }, index * delay);
            });
        });
        return Promise.all(promises);
    }

    return {
        highlightNegativeWords(markEntityAdapters) {
            return renderListOfItems(markEntityAdapters, 7);
        },
        highlightProductWords(markEntityAdapters) {
            return renderListOfItems(markEntityAdapters, 11);
        },
        highlightCaseIdentifiers(markEntityAdapters) {
            return renderListOfItems(markEntityAdapters, 17);
        },
        highlightMLEntities(markEntityAdapters) {
            return renderListOfItems(markEntityAdapters, 19);
        },
        highlightSelectedKeywords(markEntityAdapters) {
            return renderListOfItems(markEntityAdapters, 20);
        },
        highlightSearchWords(markEntityAdapters) {
            return renderListOfItems(markEntityAdapters, 22);
        },
        getEntitiesCount() {
            let entityCounter = {};
            let markers = htmlElement.querySelectorAll(htmlTag);

            markers.forEach((element) => {
                if (element.innerText.length === 0) {
                    return;
                }

                let entityType = element.getAttribute("neo-entity-type") || DATA_TYPES.otherWords;

                if (!(entityType in entityCounter)) {
                    entityCounter[entityType] = {
                        name: entityType,
                        count: 0,
                        entities: {},
                    };
                }

                entityCounter[entityType].count += 1;
                if ([DATA_TYPES.caseIdentifiers, DATA_TYPES.negativeWords, DATA_TYPES.productKeywords, DATA_TYPES.selectedKeywords, DATA_TYPES.searchWords, DATA_TYPES.otherWords].includes(entityType)) {
                    if (!(element.innerText in entityCounter[entityType].entities)) {
                        entityCounter[entityType].entities[element.innerText] = {
                            name: element.innerText,
                            label: "",
                            count: 0,
                        };
                    }
                    entityCounter[entityType].entities[element.innerText].count += 1;
                } else {
                    let attributeValue = element.getAttribute("neo-data-text");
                    let attributeLabel = element.getAttribute("neo-entity-label");
                    if (!(attributeValue in entityCounter[entityType].entities)) {
                        entityCounter[entityType].entities[attributeValue] = {
                            name: attributeValue,
                            label: attributeLabel,
                            count: 0,
                        };
                    }
                    entityCounter[entityType].entities[attributeValue].count += 1;
                }
            });

            return entityCounter;
        },
        unmark(elementAttributes = {}) {
            markInstance.unmark({element: htmlTag, ...elementAttributes});
        },
    };
}
