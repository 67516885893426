export default {
    name: "neo-badge",
    components: {},
    props: ["content", "bg", "color"],
    data() {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {},
};
