import { render, staticRenderFns } from "./ribbon.html?vue&type=template&id=2a80d0ca&scoped=true&external"
import script from "./ribbon.js?vue&type=script&lang=js&external"
export * from "./ribbon.js?vue&type=script&lang=js&external"
import style0 from "./ribbon.scss?vue&type=style&index=0&id=2a80d0ca&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a80d0ca",
  null
  
)

export default component.exports