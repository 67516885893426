import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import MultilevelAccordion from "vue-multilevel-accordion";
import {TippyComponent} from "vue-tippy";
import {v4 as uuidv4} from "uuid";
import Badge from "@shared/saas-components/badge";
import Checkbox from "@shared/saas-components/checkbox";
import NeoToggleSwitch from "@shared/saas-components/toggle-switch";
import NeoLoader from "@shared/saas-components/loader";
import NeoSearchQueries from "@shared/saas-components/searchQueries.vue";
import VueMultiselect from "@shared/saas-components/vue-multiselect";
import monitoringTimer from "@shared/saas-components/monitoring-timer";
import frequencyWidget from "@shared/saas-components/frequency-widget";
import optionsWidget from "@shared/saas-components/options-widget";
import {DateTime} from "luxon";

import {mapGetters, mapActions} from "vuex";

export default {
    name: "neo-treeview",
    components: {
        "font-awesome-icon": FontAwesomeIcon,
        MultilevelAccordion,
        tippy: TippyComponent,
        "neo-badge": Badge,
        checkbox: Checkbox,
        NeoToggleSwitch,
        NeoLoader,
        NeoSearchQueries,
        VueMultiselect,
        "monitoring-timer": monitoringTimer,
        "frequency-widget": frequencyWidget,
        "options-widget": optionsWidget,
    },
    props: {
        items: {
            type: Object,
            default: {},
        },
        checkboxes: {
            type: Boolean,
            default: false,
        },
        filterBtn: {
            type: Boolean,
            default: false,
        },
        analysisStatus: {
            type: Array,
            default: () => [],
        },
        analyzerCount: {
            type: Number,
            default: 0,
        },
        allowClick: {
            type: Boolean,
            default: false,
        },
        searchEngine: {
            type: Boolean,
            default: false,
        },
        showCountRatio: {
            type: Boolean,
            default: false,
        },
        showChecked: {
            type: Boolean,
            default: false,
        },
        heading: {
            type: Boolean,
            default: false,
        },
        selectedToolsList: {
            type: Array,
            default: () => [],
        },
        // selectedSearchQueries: {
        //     type: Array,
        //     default: () => [],
        // },
        disableMonitoring: {
            type: Boolean,
            default: false,
        },
        frequencyInput: {
            type: Boolean,
            default: false,
        },
        usersList: {
            type: Array,
            default: () => [],
        },
        products: {
            type: Array,
            default: () => [],
        },
        isMonitoring: {
            type: Boolean,
            default: false,
        },
        queriesLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedRow: "",
            selectedUser: null,
            checkedFilters: [],
            componentKey: 0,
            activeTool: null,
            currentToolSelected: "",
            isOpen: false,
            treeData: null,
            selectedSearchQueries: [],
        };
    },
    computed: {
        ...mapGetters(["searchedQueries", "getCasePersonalData", "getAnalyzerOptions"]),
        propsItems() {
            return this.items;
        },
        fetchSearchedQueries() {
            return this.searchedQueries?.filter((n) => n.tool == this.currentToolSelected && n.monitoring);
        },
        tippyName() {
            return `${uuidv4()}`;
        },
        isMonitoringEnabled() {
            // return (process.env.VUE_APP_TAG_NAME === "local" || process.env.VUE_APP_TAG_NAME === "development");
            return true;
        },
        getCaseStatus() {
            return this.getCasePersonalData && Object.keys(this.getCasePersonalData).length ? this.getCasePersonalData.case_state || "To Do" : "";
        },
        showAnalyser() {
            return this.getAnalyzerOptions.filter((res) => res.selected);
        },
    },
    filters: {
        capitalize: function(value) {
            if (!value) return "";
            return value
                .split("_")
                .map((val) => {
                    return val.charAt(0).toUpperCase() + val.slice(1);
                })
                .join(" ");
        },
        removeHttp: function(value) {
            if (!value) return "";
            let spiltArr = value.split("//")[1].split("www.");
            return spiltArr.length > 1 ? spiltArr[1] : spiltArr[0];
        },
    },
    async mounted() {
        if (this.$route.name === "Configure Case") {
            this.$store.dispatch("setCaseId", this.$route.query.case_id);
            await this.getToolsStatus();
        }
    },
    methods: {
        ...mapActions(["getToolsStatus"]),
        getBadges() {},
        exapnd() {
            let height = 0;
            if (document.querySelector(".open")) {
                [...document.querySelector(".open").parentNode.parentNode.querySelectorAll("li")].forEach((e) => (height = height + e.offsetHeight));
                document.querySelector(".open").parentNode.parentNode.querySelector(".panel").style.height = height;
            }
        },
        setSelected(rowValue) {
            // alert(rowValue)
            this.selectedRow = rowValue;
        },
        childrenLength(tree) {
            return tree?.children?.length ?? 0;
        },
        handleTreeClick(leaf, level, tree) {
            if (this.checkboxes && (level >= 0) && !tree.disabled) {
                tree.value = !tree.value;
                this.$emit("checkbox", {filter: tree.filter});
            }
            if (leaf && level >= 0) {
                this.setSelected(tree.id ? tree.id : tree.text);
            }
            this.$emit("clickEvent", {...tree, leaf});
        },
        handleMonitoringStatus(tree) {
            if (!this.queriesLoading) {
                (tree.monitoring = !tree.monitoring), this.$emit("changeMonitoringStatus", tree);
                this.componentKey += 1;
            }
        },
        handleChangeGlobal({target}) {
            this.$emit("handleChangeGlobal", target.value);
        },
        handleSelectedQueries(event, value) {
            event.stopPropagation();
            let index = this.selectedSearchQueries.findIndex((n) => n.id == value.id);
            if (index === -1) {
                this.selectedSearchQueries.push(value);
            } else this.selectedSearchQueries.splice(index, 1);
        },
        setTheCustomInput(value) {
            this.selectedUser = {
                value: value,
            };
            this.usersList.push(this.selectedUser);
        },
        updateTool(tree) {
            this.$emit("update", tree);
            this.activeTool = null;
        },
        setActiveTool(tree) {
            this.activeTool = tree;
            this.$forceUpdate();
        },
        toggleSearchQueries(tree) {
            if (this.currentToolSelected === tree.activeProcess) this.currentToolSelected = "";
            else this.currentToolSelected = tree.activeProcess;
        },
        handleSettings(event) {
            event.stopPropagation();
        },

        getTimer(runtime, format = "d MMMM yyyy HH:mm") {
            console.log(format);
            let days = 0;
            if (runtime) {
                const next_date = DateTime.fromISO(runtime);
                const curr_date = DateTime.now();
                const diff = next_date.diff(curr_date, ["days"]).toObject();

                days = parseInt(diff.days, 10);
            }
            return days;
        },

        showOptionsBgLayer() {
            this.isOpen = true;
            const optionsbgLayer = document.getElementById("optionsbgLayer").cloneNode();
            const bgCopy = document.body.appendChild(optionsbgLayer);
            bgCopy.classList = "bg-layer fixed inset-0 bg-black opacity-30 z-50";
            bgCopy.id = "options-bg-copy";
            bgCopy.style.zIndex = " 590";
            this.$refs[`options-widget-${this.treeData.text}`].initiateOptionsWidget();
        },
        hideOptionsBgLayer() {
            this.isOpen = false;
            const bgCopy = document.getElementById("options-bg-copy");
            bgCopy.remove();
            this.$refs[`options-widget-${this.treeData.text}`].resetOptionsWidget();
            return true;
        },

        getFrequency(frequency) {
            let days = parseInt(frequency, 10) || 0;
            return `${days} days`;
        },

        updateFrequency(query, isQuery, text) {
            if (isQuery) {
                query = {
                    ...query,
                    ...{
                        _id: query.id,
                        monitoring_id: query.monitoring_id,
                        tool: "search-engine-tool",
                    },
                };
            }
            this.$emit("updateFrequency", {
                query: query,
                isQuery: isQuery,
                maskedQuery: text,
            });
        },

        updateMonitoring(data) {
            this.$emit("updateMonitoring", data);
        },
        searchEngineCount(level, tree) {
            return this.searchEngine && level && tree.limit && !this.frequencyInput && this.$route.name !== "monitoring";
        },

        treelevel(heading, tree, level) {
            if (heading) {
                if (tree.globalAnalyzer) {
                    return "-ml-4";
                } else return "-ml-7";
            } else {
                if (this.searchEngine && !level) return "font-bold";
                else if (this.searchEngine && level === 1) return "ml-1";
                else if (this.searchEngine && level === 2 && !tree.monitoringSwitch) return "ml-12";
            }
        },
        levelClasses(level) {
            return !this.searchEngine ? `pl-${level * 4}` : "";
        },
    },
};
