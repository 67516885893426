import {cloneDeep} from "lodash";

export default {
    name: "badge-list",
    components: {},
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        // list: [
        //     {
        //         label: "Primary",
        //         bgColor: "bg-blue-600",
        //         textColor: "text-white",
        //     },
        // ],
        badgeShowCount: {
            type: Number,
            default: 5,
        },
    },
    data() {
        return {
            showMore: false,
        };
    },
    computed: {
        viewList() {
            return this.list.length > 0 ? cloneDeep(this.list).splice(0, this.badgeShowCount) : [];
        },
        dropdownList() {
            return cloneDeep(this.list).splice(this.badgeShowCount, this.list.length);
        },
    },
    mounted() {},
    methods: {
        computeClass(bgColor, textColor) {
            let classes = [];
            classes.push(bgColor ? bgColor : "bg-brand");
            classes.push(textColor ? textColor : "text-white");
            return classes;
        },
        onShowMore() {
            this.showMore = !this.showMore;
        },
        onClickOutside() {
            this.showMore = false;
        },
    },
};
