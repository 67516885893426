import DatePicker from "vue2-datepicker";
import {DateTime} from "luxon";
import NeoLoader from "@/components/loader";
import {TippyComponent} from "vue-tippy";

export default {
    name: "ungrouped-filter",
    components: {
        DatePicker,
        NeoLoader,
        tippy: TippyComponent,
    },
    props: {
        fetchAnalysisData: {
            type: Function,
            default: () => 1,
        },
        placeHolders: {
            type: Object,
            default: null,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        handleExpandCollapse: {
            type: Function,
            default: () => 1,
        },
        FilterCategory: {
            type: Object,
            default: null,
        },
        start_date: {
            type: String,
            default: "",
        },
        end_date: {
            type: String,
            default: "",
        },
        run_status: {
            type: String,
            default: "all",
        },
        monitoring_filter_val: {
            type: String,
            default: "all",
        },
        visited_filter_val: {
            type: String,
            default: "all",
        },
        done_filter_val: {
            type: String,
            default: "all",
        },
        params_val: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            adverse_tag: false,
            dateRange: [],
            runStatus: "run",
            paginate: {
                tag: 1,
                organisations: 1,
                persons: 1,
                locations: 1,
            },
            tags: [],
            persons: [],
            locations: [],
            organisations: [],
            monitoringFilterVal: "all",
            doneFilterVal: "all",
            visitedFilterVal: "all",
        };
    },
    methods: {
        async handleAnalyseFilter() {
            let docs = {
                start_date: this.dateRange[0],
                end_date: this.dateRange[1],
                queries: {
                    run_status: this.runStatus,
                    monitoringFilterVal: this.monitoringFilterVal,
                },
                visitedFilterVal: this.visitedFilterVal,
                doneFilterVal: this.doneFilterVal,
                tags: this.tags,
                organisations: this.organisations,
                persons: this.persons,
                locations: this.locations,
                // ADDED adverse filter TECH-3722
                adverse: this.adverse_tag,
            };
            this.$modal.hide("ungrouped-filter");
            await this.fetchAnalysisData(docs);
        },
        handleExpandCollapseFilters(placholderCat) {
            if (placholderCat) {
                this.handleExpandCollapse(placholderCat);
            }
        },
        paginateFilter(tag) {
            // if (this.paginate[tag]) {}
            this.paginate[tag]++;
        },
        handleClear() {
            this.dateRange = [];
        },
        resetFilter() {
            this.adverse_tag = false;
            this.dateRange = [];
            this.runStatus = "run";
            this.paginate = {
                tag: 1,
                organisations: 1,
                persons: 1,
                locations: 1,
            };
            this.tags = [];
            this.persons = [];
            this.locations = [];
            this.organisations = [];
            this.monitoringFilterVal = "all";
            this.doneFilterVal = "all";
            this.visitedFilterVal = "all";
        },
        beforeOpen() {
            if (this.start_date && this.end_date) {
                this.dateRange = [this.start_date, this.end_date];
            }
            this.runStatus = this.run_status;
            this.tags = this.params_val.tags;
            this.persons = this.params_val.persons;
            this.locations = this.params_val.locations;
            this.organisations = this.params_val.organisations;
            this.monitoringFilterVal = this.monitoring_filter_val;
            this.doneFilterVal = this.done_filter_val;
            this.visitedFilterVal = this.visited_filter_val;
            this.adverse_tag = this.params_val.adverse;
        },
        onCloseFilter() {
            this.$modal.hide("ungrouped-filter");
        },
    },
    computed: {
        formattedDate() {
            let formatDate = ["Starting Date", "Ending Date"];
            if (this.dateRange.length > 0) {
                let start = this.dateRange[0];
                start = DateTime.fromMillis(start);
                start = start.toFormat("dd/MM/yyyy");

                let end = this.dateRange[1];
                end = DateTime.fromMillis(end);
                end = end.toFormat("dd/MM/yyyy");

                formatDate = [start, end];
                return formatDate;
            } else return formatDate;
        },

        tagPaginated() {
            return this.placeHolders?.tags.slice(0, this.paginate.tag * 20);
        },

        organisationsPaginated() {
            return this.placeHolders?.organisations.slice(0, this.paginate.organisations * 20);
        },

        locationsPaginated() {
            return this.placeHolders?.locations.slice(0, this.paginate.locations * 20);
        },
        personsPaginated() {
            return this.placeHolders?.persons.slice(0, this.paginate.persons * 20);
        },
    },
};
