<template>
    <div class="p-1" :class="{padding: data.children && data.children.length > 0, 'padding-no-child': !data.children || !data.children.length}">
        <div class="flex items-start">
            <font-awesome-icon @click="toggleCollapse" :icon="['fas', !collapsed ? 'caret-down' : 'caret-right']" class="mr-2" v-if="data.children && data.children.length > 0" />

            <input type="checkbox" :checked="data.checked" v-model="data.checked" @change="handleCheck($event, data.children)" v-if="data.children != null" class="w-4 h-4 mr-2 cursor-pointer" />
            <input type="checkbox" :checked="data.checked" v-model="data.checked" @change="handleCheck($event, data)" v-else class="w-4 h-4 mr-2 cursor-pointer" />

            <span class="flex flex-wrap break-all -mt-1" style="width: calc(100% - 40px)" @click="toggleCollapse"> {{ data.category }} ({{ data.count }}) </span>
        </div>

        <ul class="subchild" v-show="!collapsed && data.children">
            <li :class="{'cst-border': data.children && data.children.length > 0, '': !data.children || !data.children.length}" v-for="(child, index) in data.children" :key="index">
                <TreeItem :data="child" @toggleTopics="$emit('toggleTopics', $event)"></TreeItem>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "TreeItem",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            collapsed: true,
        };
    },
    methods: {
        toggleCollapse() {
            this.collapsed = !this.collapsed;
        },
        handleCheck(event, data) {
            this.$emit("toggleTopics", {
                value: event.target.checked,
                data,
            });
        },
    },
};
</script>
<style lang="scss" scoped>
ul {
    li {
        list-style-type: none;
    }
}

.padding-no-child {
    padding-left: 18px !important;
}
.cst-border {
    border-left: 1px solid rgb(148, 145, 145);
}

.subchild li {
    padding-left: 20px !important;
}

ul#subchild:hover {
    background: transparent !important;
}
</style>
